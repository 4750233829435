<script type="ts">
	import '../style/app.scss';
	import { onMount } from 'svelte';

	let darkMode: boolean | undefined;

	onMount(() => {
		darkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
	});

	function toggleDarkMode(e: KeyboardEventInit) {
		if (e.key !== 'Escape') {
			return;
		}
		darkMode = !darkMode;
	}

	$: if (undefined !== darkMode) {
		if (darkMode) {
			document.body.classList.remove('light');
			document.body.classList.add('dark');
		} else {
			document.body.classList.remove('dark');
			document.body.classList.add('light');
		}
	}
</script>

<div class="main">
	<slot />
</div>

<svelte:window on:keydown={toggleDarkMode} />
<svelte:head>
	<link rel="preconnect" href="https://fonts.googleapis.com" />
	<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin="" />
	<link
		href="https://fonts.googleapis.com/css2?family=Averia+Serif+Libre:wght@300&amp;display=swap"
		rel="stylesheet"
	/>
</svelte:head>

<style lang="scss" global>
	@import '../style/app.scss';
</style>
